import { render, staticRenderFns } from "./sintervalAnalysis.vue?vue&type=template&id=510f13a3&scoped=true"
import script from "./sintervalAnalysis.vue?vue&type=script&lang=js"
export * from "./sintervalAnalysis.vue?vue&type=script&lang=js"
import style0 from "./sintervalAnalysis.vue?vue&type=style&index=0&id=510f13a3&prod&lang=scss&scoped=dailyPassengerFlow"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510f13a3",
  null
  
)

export default component.exports